import React from 'react';

function Footer() {
    return (
        <>
            <footer className="riseabove pt-5">
                <div className="container text-center">
                    <p>ChopChop &copy; 2021</p>
                </div>
            </footer>
        </>
    )
}

export default Footer;