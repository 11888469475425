import React from "react";
import { Link } from "react-router-dom";
import {
	RecoilRoot,
	atom,
	selector,
	useRecoilState,
	useRecoilValue,
} from "recoil";
import { sidebarView, sidebarToggle } from "../atoms/atoms";
import Profile from "./profile";
import Button from "react-bootstrap/Button";

export default function SidebarNav() {
	const [sideBarView, setSideBarView] = useRecoilState(sidebarView);
	const [sidebarToggleState, setSidebarToggleState] =
		useRecoilState(sidebarToggle);
	function closeSidebar() {
		console.log("thing");
		setSidebarToggleState(false);
	}
	return (
		<div className="sidebar-nav">
			<ul>
				<li>
					<Button
						variant="secondary"
						className={sideBarView === "chat" ? "active" : "inactive"}
						onClick={() => setSideBarView("chat")}
					>
						Chat
					</Button>
				</li>
				<li>
					<Button
						variant="secondary"
						className={sideBarView === "past-games" ? "active" : "inactive"}
						onClick={() => setSideBarView("past-games")}
					>
						Past Games
					</Button>
				</li>
				<span className="profile">
					<Profile />
				</span>
				<span className="closeSidebar">
					<button onClick={closeSidebar}></button>
				</span>
			</ul>
		</div>
	);
}
