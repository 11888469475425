import { auth, db } from "../services/firebase";
import { ref, getDatabase } from "firebase/database";
import React, { Component, useEffect, useRef, useState } from "react";
import {
	RecoilRoot,
	atom,
	selector,
	useRecoilState,
	useRecoilValue,
} from "recoil";
import {
	currency,
	walletAddress,
	isWalletConnected,
	requestingConnection,
	balance,
	user,
	sidebarView,
	profileModal,
} from "../atoms/atoms";

export default function Settings(event) {
	const [walAddress, setWalAddress] = useRecoilState(walletAddress);
	const [userObject, setUserObject] = useRecoilState(user);
	const [settings, setSettings] = useState({
		block: 0,
		restart: false,
		percentage: 0,
	});
	useEffect(() => {
		const fetchSettings = async () => {
			const ref = db.ref("settings");

			ref.on("value", (snap) => {
				if (!snap.exists()) {
					let initVals = {
						block: 0,
						restart: true,
						percentage: 20,
					};
					db.ref("/settings/").update(initVals);
				}
			});
		};
		fetchSettings().catch(console.error);
	}, []);
	useEffect(() => {
		if (!walAddress) {
			console.log("no wal address");
			return;
		}
		console.log("wal address found");
		const ref = db.ref("settings");

		ref.on("value", (snap) => {
			if (!snap.exists()) {
				return;
			}
			let block = snap.val().block;
			let restart = snap.val().restart;
			let percentage = snap.val().percentage;
			if (block) {
				setSettings((settings) => ({
					...settings,
					block: Number(block),
				}));
			}
			if (restart) {
				setSettings((settings) => ({
					...settings,
					restart: restart,
				}));
			}
			if (percentage) {
				setSettings((settings) => ({
					...settings,
					percentage: Number(percentage),
				}));
			}
		});
		// if (restart) setSettings(restart);
		// if (percentage) setSettings(percentage);
		// console.log(settings);
	}, [ref, userObject]);

	const handleBlockInputChange = (event) => {
		event.persist();
		setSettings((settings) => ({
			...settings,
			block: Number(event.target.value),
		}));
	};

	const handlePercentageInputChange = (event) => {
		event.persist();
		setSettings((settings) => ({
			...settings,
			percentage: Number(event.target.value),
		}));
	};

	const saveSettings = async (e) => {
		e.preventDefault();
		console.log(settings);
		db.ref("/settings/").update(settings);
	};

	return (
		<>
			<h2>Settings</h2>
			<form action="">
				<label>
					Block:
					<input
						id="block"
						className="form-field"
						type="number"
						placeholder="Block #"
						name="block"
						value={settings.block}
						onChange={handleBlockInputChange}
					/>
				</label>
				<label>
					Global Restart - Should games restart when completed: True{" "}
					<input
						type="radio"
						label="True"
						checked={settings.restart === true}
						value="true"
						onClick={() =>
							setSettings((settings) => ({
								...settings,
								restart: true,
							}))
						}
					/>
					False{" "}
					<input
						type="radio"
						label="False"
						checked={settings.restart === false}
						value="false"
						onClick={() =>
							setSettings((settings) => ({
								...settings,
								restart: false,
							}))
						}
					/>
				</label>
				<label>
					Markup Percentage:
					<input
						id="percentage"
						className="form-field"
						type="number"
						placeholder="Percentage (20 = 20%)"
						name="percentage"
						value={settings.percentage}
						onChange={handlePercentageInputChange}
					/>
				</label>

				<button className="btn btn-primary" onClick={saveSettings}>
					Save
				</button>
			</form>
		</>
	);
}
