import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Link, Outlet } from "react-router-dom";
import Games from "../components/games";
import {
	RecoilRoot,
	atom,
	selector,
	useRecoilState,
	useRecoilValue,
} from "recoil";

export default function Game() {
	return (
		<div className="wrapper gamewrap">
			<Header></Header>
			<div className="game riseabove">
				<Outlet />
				<Games />
				<Footer></Footer>
			</div>
		</div>
	);
}
