import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Game from "./pages/game";
import Admin from "./pages/admin";
import GlobalNotification from "./pages/global-notification";
import { PrivateRoute } from "./pages/privateRoute";
import ProfileModal from "./components/profileModal";
import { ToastContainer, toast } from "react-toastify";
import { RecoilRoot } from "recoil";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles/_app.scss";
import Sidebar from "./components/sidebar";

import { Web3Provider } from "./Web3Provider";

export default function App() {
	return (
		<RecoilRoot>
			<ToastContainer pauseOnFocusLoss={false} />
			<GlobalNotification />
			<Web3Provider>
				<Router>
					<Routes>
						<Route path="/" element={<Home />} />
						{/* <Route path="/wallet" element={<Wallet />} /> */}
						<Route path="/game/*" element={<Game />}>
							<Route path="" element={<Sidebar />} />
						</Route>
						<Route exact path="/fbadm" element={<PrivateRoute />}>
							<Route exact path="/fbadm" element={<Admin />} />
						</Route>
					</Routes>
				</Router>
			</Web3Provider>

			<ProfileModal />
		</RecoilRoot>
	);
}
