import React, {
	useRef,
	useCallback,
	useEffect,
	useState,
	Suspense,
} from "react";
import {
	RecoilRoot,
	atom,
	selector,
	useRecoilState,
	useRecoilValue,
} from "recoil";
import { auth, db } from "../services/firebase";
import {
	currency,
	walletAddress,
	isWalletConnected,
	requestingConnection,
	balance,
	user,
	nameList,
} from "../atoms/atoms";

let nameArr = {};
export default function useFetchNames(ids) {
	const [names, setNames] = useRecoilState(nameList);

	let counter = 0;
	const fetchNames = async (ids) => {
		if (typeof ids == "undefined") {
			return;
		}
		ids.forEach(async (id) => {
			if (nameArr[id] !== undefined) {
				// console.log("We found it, captain!");
				return;
			}
			counter++;
			let nameRef = db.ref("/users/ids/" + id);
			const nameSnapshot = await nameRef.once("value");
			if (!nameSnapshot.exists()) {
				return;
			}
			nameArr = {
				...nameArr,
				[id]: nameSnapshot.val().name,
			};
			// let uniqueArr = unique(nameArr, "name");
			setNames((names) => ({
				...names,
				...nameArr,
			}));
		});

		return true;
	};

	useEffect(() => {
		fetchNames(ids);
	}, []);

	return [fetchNames];
}
