import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "@rainbow-me/rainbowkit/styles.css";

import {
	getDefaultConfig,
	RainbowKitProvider,
	darkTheme,
	midnightTheme,
} from "@rainbow-me/rainbowkit";
import { WagmiProvider, createConfig, http } from "wagmi";
import { avaxLocal } from "./customChains";
import { mainnet, avalanche, avalancheFuji } from "wagmi/chains";
import chopchopLogo from "./assets/ChopChop-MdResLogo.png";

// Remote

var config = getDefaultConfig({
	// Your dApps chains
	chains: [avalancheFuji],
	transports: {
		// RPC URL for each chain
		[avalancheFuji.id]: http(avalancheFuji.rpcUrls.default.http[0]),
	},
	// Required API Keys
	projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,

	// Required App Info
	appName: "ChopChop",

	// Optional App Info
	appDescription:
		"ChopChop.gg - A lighting quick lotto with real-time payouts.",
	appUrl: "https://chopchop.gg", // your app's url
	appIcon: chopchopLogo, // your app's icon, no bigger than 1024x1024px (max. 1MB)
});

// Local
if (
	window.location.hostname === "localhost" ||
	window.location.hostname === "local.chopchop.gg"
) {
	var config = getDefaultConfig({
		// Your dApps chains
		chains: [avaxLocal],
		transports: {
			[avaxLocal.id]: http(avaxLocal.rpcUrls.default.http[0]),
		},

		// Required API Keys
		projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,

		// Required App Info
		appName: "ChopChop",

		// Optional App Info
		appDescription:
			"ChopChop.gg - A lighting quick lotto with real-time payouts.",
		appUrl: "https://chopchop.gg", // your app's url
		appIcon: "", // your app's icon, no bigger than 1024x1024px (max. 1MB)
	});
}
const queryClient = new QueryClient();

export const Web3Provider = ({ children }) => {
	return (
		<WagmiProvider config={config}>
			<QueryClientProvider client={queryClient}>
				<RainbowKitProvider
					theme={midnightTheme({
						accentColor: "#B5489D",
						accentColorForeground: "white",
						overlayBlur: "small",
					})}
				>
					{children}
				</RainbowKitProvider>
			</QueryClientProvider>
		</WagmiProvider>
	);
};
