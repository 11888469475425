import { ConnectButton } from "@rainbow-me/rainbowkit";
import React, { useRef, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
	useBalance,
	useAccount,
	useWatchPendingTransactions,
	useChainId,
	usePublicClient,
	useConfig,
	createStorage,
	deserialize,
	useDisconnect,
} from "wagmi";
import { watchPendingTransactions } from "@wagmi/core";
import autoAnimate from "@formkit/auto-animate";

import { formatUnits, getBalance } from "viem";
import {
	currency,
	walletAddress,
	isWalletConnected,
	requestingConnection,
	balance,
	user,
	nameList,
} from "../atoms/atoms";
import { HandleLogIn } from "./login.js";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, signOut, signInWithCustomToken } from "firebase/auth";
import { auth, db, localAuth, analytics, logEvent } from "../services/firebase";
import Avatar from "boring-avatars";
import notifications from "./notifications.js";

const functions = getFunctions();

export function WalletConnect() {
	// Main Chain ID
	var enforcedChain = process.env.REACT_APP_CHAIN_ID;
	// WAGMI SETTINGS

	// Identify wallet chain change
	const { chain } = useChainId();

	// Address setup/listeners
	const {
		address,
		isReconnecting,
		activeConnector,
		isConnecting,
		isConnected,
		isDisconnected,
		chainId,
	} = useAccount();
	const { data, isError, isLoading } = useBalance({
		address: address,
		chainId: chain,
	});
	const [transactions, setTransactions] = useState();
	const [invalidChain, setinvalidChain] = useState(false);
	const { disconnect } = useDisconnect();

	// RECOIL SETTINGS
	const [names, setNames] = useRecoilState(nameList);
	const [userBalance, setBalance] = useRecoilState(balance);
	const [userObject, setUserObject] = useRecoilState(user);
	const [walAddress, setWalAddress] = useRecoilState(walletAddress);
	const [selectedCurrency, setSelectedCurrency] = useRecoilState(currency);

	const publicClient = usePublicClient();

	const config = useConfig();
	const unwatch = watchPendingTransactions(config, {
		onTransactions(transactions) {
			setTransactions(transactions);
		},
	});

	// Use to update wallet balance
	var ref = useRef();
	function setFullUser(id) {
		if (id == "") {
			return;
		}
		let userRef = db.ref("users/ids/" + id);

		let userData = {};
		userRef.once("value", (snapshot) => {
			userData = snapshot.val();
			if (!snapshot.exists()) {
				const submitDateTime = Date.now();

				userData = {
					uid: id,
					createdOn: submitDateTime,
				};
				userRef.set(userData);

				setUserObject(userData);
				const newName = httpsCallable(functions, "nameControls-nameNew");

				newName({ id: id, userData: userData }).then((result) => {
					const name = result.data.name;

					userData = {
						...userData,
						name: name,
					};
					setUserObject(userData);
					setNames((names) => ({
						...names,
						[id]: name,
					}));
				});

				return;
			}
			setUserObject(userData);
		});
	}

	useEffect(() => {
		if (address) {
			// if (walAddress != address.toLowerCase()) {
			setWalAddress(address.toLowerCase());
			// }
		}
	}, [
		address,
		isConnected,
		isConnecting,
		isDisconnected,
		isReconnecting,
		activeConnector,
	]);

	// Master Balance Update
	useEffect(() => {
		if (!data || !address) {
			setBalance(0);
			return;
		}
		const updateBalance = async () => {
			const totBalance = await publicClient.getBalance({
				address: address,
			});

			var newBalance = formatUnits(totBalance, data.decimals);
			var refinedBalance = parseFloat(newBalance).toFixed(4);
			setBalance(refinedBalance);
		};

		updateBalance();
	}, [data, transactions]);

	/// Listen for disconnect. Remove wallet Address.
	useEffect(() => {
		if (!address) {
			setWalAddress("");
		}
	}, [isDisconnected]);

	// Listen for chain change away from Avax
	var firstLoad = true;
	// useEffect(() => {
	// 	if (!chainId) {
	// 		return;
	// 	}
	// 	if (!address && firstLoad) {
	// 		firstLoad = false;
	// 		return;
	// 	}
	// 	// FINALLLLLYYYYY
	// 	// Use this to enforce switching back to avax.

	// 	if (chainId != enforcedChain) {
	// 		setinvalidChain(true);
	// 		setFullUser("");
	// 		disconnect();
	// 		notifications.chain();
	// 	}
	// }, [chainId]);

	useEffect(() => {
		if (walAddress) {
			setSelectedCurrency(publicClient.chain.nativeCurrency.symbol);

			const userLogin = async () => {
				let userId = await HandleLogIn(walAddress);
				await setFullUser(userId);
				localStorage.setItem("isWalletConnected", true);
				// logEvent(analytics, 'user_logged_in', { account: `'${walAddress}'` });
			};
			userLogin().catch(console.error);
			return;
		}
	}, [walAddress]);

	return (
		<div className="wallet-inner">
			<ConnectButton.Custom>
				{({
					account,
					chain,
					openAccountModal,
					openChainModal,
					openConnectModal,
					authenticationStatus,
					mounted,
				}) => {
					// Note: If your app doesn't use authentication, you
					// can remove all 'authenticationStatus' checks
					const ready = mounted;
					const connected = ready && account && chain;

					return (
						<>
							{(() => {
								if (!connected) {
									return (
										<div>
											<button
												className="btn btn-primary"
												onClick={openConnectModal}
											>
												Connect Wallet
											</button>
										</div>
									);
								}

								if (chain.unsupported) {
									return (
										<div>
											<button
												className="btn btn-primary"
												onClick={openChainModal}
											>
												Wrong Network
											</button>
										</div>
									);
								}

								return (
									<>
										{userBalance >= 0 && walAddress && (
											<div className="address">
												{userBalance} {selectedCurrency}
											</div>
										)}

										{connected && (
											<button onClick={openAccountModal} className="balance">
												<div>
													{connected ? account.displayName : "Connect Wallet"}
												</div>
												<div className="avatar">
													<Avatar
														size={16}
														name={userObject.uid}
														variant="marble"
														colors={[
															"#704C09",
															"#D69111",
															"#B0770F",
															"#8E00FF",
															"#FF00F7",
														]}
													/>
												</div>
											</button>
										)}
									</>
								);
							})()}
						</>
					);
				}}
			</ConnectButton.Custom>
			{/* <ConnectButton /> */}
			{/* <ConnectKitButton.Custom>
				{({ isConnected, show, truncatedAddress, ensName }) => {
					return (
						<>
							{userBalance >= 0 && walAddress && (
								<div className="address">
									{userBalance} {selectedCurrency}
								</div>
							)}
							{!isConnected && (
								<div>
									<button className="btn btn-primary" onClick={show}>
										Connect Wallet
									</button>
								</div>
							)}
							{isConnected && (
								<button onClick={show} className="balance">
									<div>{isConnected ? truncatedAddress : "Connect Wallet"}</div>
									<div className="avatar">
										<Avatar
											size={16}
											name={userObject.uid}
											variant="marble"
											colors={[
												"#704C09",
												"#D69111",
												"#B0770F",
												"#8E00FF",
												"#FF00F7",
											]}
										/>
									</div>
								</button>
							)}
						</>
					);
				}}
			</ConnectKitButton.Custom> */}
		</div>
	);
}
export default WalletConnect;
