import SidebarNav from "../components/sidebarNav";
import {
	RecoilRoot,
	atom,
	selector,
	useRecoilState,
	useRecoilValue,
} from "recoil";
import { auth, db } from "../services/firebase";
import React, { Component, useEffect, useRef, useState } from "react";
import {
	currency,
	walletAddress,
	isWalletConnected,
	requestingConnection,
	balance,
	user,
	nameList,
} from "../atoms/atoms";
import { ref, getDatabase } from "firebase/database";
import moment from "moment";
import useFetchNames from "../components/nameManager.js";
import autoAnimate from "@formkit/auto-animate";

export default function PastGames() {
	const [completedGamesList, setCompletedGamesList] = useState();
	const [names, setNames] = useRecoilState(nameList);
	const [fetchNames] = useFetchNames();

	const address = useRecoilValue(walletAddress);
	useEffect(() => {
		const ref = db.ref("games/completed/");
		ref
			.endAt()
			.limitToLast(30)
			.on("value", (snapshot) => {
				let completedGameList = [];
				let winnerList = [];
				snapshot.forEach((snap) => {
					if (!snap.val()) {
						return;
					}
					if (!snap.val().timestamp) {
						return;
					}
					let game = snap.val();
					game["winner"] = game["winner"].toLowerCase();
					game["gameId"] = snap.key;
					winnerList.push(game["winner"]);
					completedGameList.push(game);
				});
				fetchNames(winnerList);
				completedGameList.sort(function (a, b) {
					return b.endTimestamp - a.endTimestamp;
				});
				setCompletedGamesList(completedGameList);
			});

		return () => ref.off();
	}, [ref]);

	return (
		<div className="past-games">
			<ul>
				{completedGamesList &&
					completedGamesList.map((game) => {
						return (
							<>
								<li
									className={`past-game ${
										game.winner === address ? "winner" : ""
									}`}
								>
									<div className="game-content">
										<div className="winning-number">
											<div className="wintitle">Winning Number</div>
											<div className="number">{game.winningTicket}</div>
										</div>
										<div className="details">
											<h4>{game.winner === address ? `You Won!!` : ""}</h4>
											<div className="det id">
												<div className="title">Id:</div>
												<div className="detail">chop{game.gameId}</div>
											</div>
											<div className="det winner">
												<div className="title">Winner:</div>
												<div className="detail"> {names[game.winner]}</div>
											</div>
											<div className="det payout">
												<div className="title">Payout:</div>
												<div className="detail">{game.payout} AVAX</div>
											</div>
											<div className="end-time">
												{moment(game.endTimestamp).fromNow()}
											</div>
										</div>
									</div>
								</li>
							</>
						);
					})}
			</ul>
		</div>
	);
}
