import { atom } from "recoil";
// ATOMS for use in Recoil State Management

const currency = atom({
	key: "currency",
	default: "",
});

const walletAddress = atom({
	key: "walletAddress",
	default: "",
});

const isWalletConnected = atom({
	key: "isWalletConnected",
	default: false,
});
const requestingConnection = atom({
	key: "requestingConnection",
	default: false,
});
const balance = atom({
	key: "balance",
	default: 0,
});
const user = atom({
	key: "user",
	default: false,
});
const sidebarView = atom({
	key: "sidebarView",
	default: "chat",
});
const sidebarToggle = atom({
	key: "sidebarToggle",
	default: false,
});
const nameList = atom({
	key: "nameList",
	default: {},
});
const profileModal = atom({
	key: "profileModal",
	default: false,
});
export {
	currency,
	walletAddress,
	isWalletConnected,
	requestingConnection,
	balance,
	user,
	sidebarView,
	sidebarToggle,
	nameList,
	profileModal,
};
