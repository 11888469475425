import { db } from "../services/firebase";
import { ref } from "firebase/database";
import notifications from "../components/notifications";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { walletAddress } from "../atoms/atoms";

export default function GlobalNotification() {
	const address = useRecoilValue(walletAddress);
	function notifyWinner(payout) {
		notifications.winnerNotify("You won " + payout + " AVAX!");
	}
	function notifyPublic(winner, payout) {
		const userNameRef = db.ref("users/ids/" + winner + "/name");
		userNameRef.once("value", (name) => {
			var userName = name.val();
			notifications.publicNotify(userName + " won " + payout + " AVAX!");
		});
	}

	var isInitialMount = true;
	useEffect(() => {
		if (!address) {
			return;
		}
		const ref = db.ref("games/completed");
		ref
			.endAt()
			.limitToLast(1)
			.on("child_added", function (snapshot) {
				// all records after the last continue to invoke this function
				if (isInitialMount) {
					isInitialMount = false;
				} else {
					if (address) {
						var winner = snapshot.val().winner;
						var winnerAddrLower = winner.toLowerCase();
						if (address == winnerAddrLower) {
							notifyWinner(snapshot.val().payout);
						} else {
							notifyPublic(winnerAddrLower, snapshot.val().payout);
						}
					}
				}
			});
		return () => ref.off();
	}, [ref, address]);
}
