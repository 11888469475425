import {
	RecoilRoot,
	atom,
	selector,
	useRecoilState,
	useRecoilValue,
} from "recoil";
import Timer from "./timer";
import GameIcon from "../assets/avalanche-logo.jpg";
import { auth, db, analytics, logEvent } from "../services/firebase";
import { ref, getDatabase } from "firebase/database";
import React, { Component, useEffect, useRef, useState } from "react";
import {
	currency,
	walletAddress,
	isWalletConnected,
	requestingConnection,
	balance,
	user,
	nameList,
} from "../atoms/atoms";
import { addressShortened } from "../atoms/selectors";

import { utils } from "ethers";
import useFetchNames from "../components/nameManager.js";

import {
	usePublicClient,
	useWriteContract,
	useConfig,
	useWalletClient,
	useEstimateFeesPerGas,
	useConnections,
} from "wagmi";
import {
	simulateContract,
	//writeContract
} from "@wagmi/core";
import { parseEther, parseGwei, parseUnits, formatEther } from "viem";

import autoAnimate from "@formkit/auto-animate";
import axios from "axios";

import { getFunctions, httpsCallable } from "firebase/functions";
import spinner from "./spinner";
import notifications from "./notifications";
const chopchopAbi = require("../assets/abi/chopchop.json");

// Set up callable functions for JWT creation
const functions = getFunctions();
const ping = httpsCallable(functions, "ping-ping");

export default function Games() {
	const [gameList, setGamesList] = useState();
	const [bidObject, setBidObject] = useState({});
	const [gasEstimate, setGasEstimate] = useState();
	const [names, setNames] = useRecoilState(nameList);
	const [fetchNames] = useFetchNames();
	const [userBalance, setBalance] = useRecoilState(balance);
	const walletConnStatus = useRecoilValue(isWalletConnected);
	const address = useRecoilValue(walletAddress);
	const addressShort = useRecoilValue(addressShortened);
	const selectedCurrency = useRecoilValue(currency);

	const walletClientResult = useWalletClient();
	const config = useConfig();
	const publicClient = usePublicClient();

	const gasFees = useEstimateFeesPerGas();

	const connections = useConnections();

	const { data, error, isPending, variables, status, writeContract } =
		useWriteContract({ config });
	// Contract Setup
	const chopContractAddress = process.env.REACT_APP_AVAX_CONTRACT;
	// const contract = new Contract(chopContractAddress, chopInterface);

	// const { state, send } = useContractFunction(contract, "placeBid", {});
	function wakeyWakey() {
		try {
			const url = process.env.REACT_APP_BACKEND_URL;
			const endpoint = "/ping";
			const enliven = axios
				.get(url + endpoint)
				.then((res) => {
					if (res.data) {
						// Arise
						// console.info("pong");
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	}
	useEffect(() => {
		wakeyWakey();
	}, []);
	useEffect(() => {
		const ref = db.ref("games/active/");

		ref.on("value", (snapshot) => {
			let gameList = [];
			snapshot.forEach((snap) => {
				if (!snap.val()) {
					return;
				}
				let game = snap.val();
				let players = [];
				game["gameId"] = snap.key;
				gameList.push(game);
				const tickets = game.tickets;
				tickets.forEach((player) => {
					if (player.status == "open") {
						return;
					}
					players.push(player.buyer);
				});
				fetchNames(players);
			});
			gameList.sort(function (a, b) {
				return b.timestamp - a.timestamp;
			});
			setGamesList(gameList);
		});

		return () => ref.off();
	}, [ref]);

	// Winning game animation handler
	// When a new game is added to "action" node
	// this function triggers and initiates the winning
	// animation function "spinTheWheel" over at spinner.js

	useEffect(() => {
		const ref = db.ref("game-index/action");

		ref.on("value", (snapshot) => {
			snapshot.forEach((snap) => {
				var activeGame = db.ref("games/active/" + snap.key);
				activeGame.once("value", (singleGame) => {
					var totTickets = singleGame.val().totTickets;
					var winningTicket = singleGame.val().winningTicket;

					spinner(snap.key, totTickets, winningTicket);
				});
			});
		});
		return () => ref.off();
	}, [ref]);

	const handleBid = async (gameId, ticketNum, bidPrice) => {
		if (!address) {
			notifications.error("Please connect wallet to play.");
			return;
		}
		if (!publicClient) {
			return;
		}
		if (userBalance < bidPrice) {
			notifications.error(
				"You do not have enough " + selectedCurrency + " to buy this slot."
			);
		}
		try {
			const result = await simulateContract(config, {
				abi: chopchopAbi,
				address: chopContractAddress,
				functionName: "placeBid",
				value: parseEther(`${bidPrice}`),
				args: [gameId, ticketNum],
				chainId: publicClient.chain.id,
			});
			if (result) {
				makeBid(result.request);
			}
		} catch (error) {
			var details = error.details;
			if (details.includes("revert ")) {
				var err = details.split("revert ")[1];
				notifications.error(err);
			} else {
				notifications.error("Something went wrong, try again...");
			}
		}
	};

	const makeBid = (result) => {
		writeContract(result);
	};
	useEffect(() => {
		// return;
		if (status == "success") {
			var bidGameId = variables.args[0];
			var bidTicketNum = variables.args[1];

			notifications.bidConfirmed(
				"Bid " + bidTicketNum + " Confirmed, awaiting network finalization..."
			);
			logEvent(analytics, "slot_purchase", {
				gameId: bidGameId,
				ticketNumber: bidTicketNum,
			});
		}

		if (status == "error") {
			console.log(error);
			console.log(walletClientResult);
			notifications.error("Error - Bid not captured, try again...");
		}
	}, [status]);
	const parentRef = useRef(null);
	useEffect(() => {
		if (parentRef.current) {
			autoAnimate(parentRef.current);
		}
	}, [parentRef]);

	return (
		<div key="games" className="games-container">
			<div className="game-column">
				<h1>Current Games</h1>
				<div key={parentRef} ref={parentRef}>
					{gameList &&
						gameList.map((game) => {
							return (
								<div
									key={`chop${game.gameId}`}
									id={`chop${game.gameId}`}
									className="single-game"
								>
									<div className="game-header">
										<div className="details">
											<div className="det lg-det">
												<div className="title">Payout:</div>
												<div className="value">{game.payout} AVAX</div>
											</div>
											<div className="det md-det">
												<div className="title">Per Slot:</div>
												<div className="value">{game.costPerTicket} AVAX</div>
											</div>
											<div className="det sm-det">
												<div className="title">Id:</div>
												<div className="value"> chop{game.gameId}</div>
											</div>
										</div>
										<div key={`icon${game.gameId}`} className="icon">
											<img src={GameIcon} alt="" />
										</div>
									</div>
									<div key={`body${game.gameId}`} className="game-body">
										{Object.entries(game.tickets).map(([number, ticket]) => {
											return (
												<div
													key={`ticket${game.gameId}-${number}`}
													className={`slot half ${ticket.status}`}
												>
													<div className="slot-detail">
														<div className="buyer">
															{number}.{" "}
															{ticket.buyer ? names[ticket.buyer] : "Slot Open"}
														</div>
													</div>
													<div
														className="bid"
														onClick={() => {
															if (ticket.status != "open") return;
															handleBid(
																game.gameId,
																number,
																game.costPerTicket
															);
															wakeyWakey();
														}}
													>
														{ticket.status === "pending" ? "Pending" : "Buy"}
													</div>
												</div>
											);
										})}
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
}
