// give range of numbers
// randomize numbers while slowing down
// finally, land on pre-selected number
//
// Timer of 3 seconds
// start fast, then slow down to the final number
//

// use class to indicate current number "selected"
// function should have 1 number as parameter

/* function spinTheWheel(_winningNumber) {
var timer = 3;
var random = Math.floor(Math.random() * 8) + 1;
} */
/* setTimeout(500) */
const sleep = (milliseconds) => {
	return new Promise((resolve) => setTimeout(resolve, milliseconds));
};
const clearItem = (gameId) => {
	var element = document.getElementById(gameId);
	if (element == null) {
		return;
	}
	var litClasses = element.getElementsByClassName("winnerAnim");
	if (litClasses == null) {
		return;
	}
	for (var ii = 0; ii < litClasses.length; ii++) {
		litClasses[ii].classList.remove("winnerAnim");
	}
};

export default async function spinTheWheel(gameId, total, winningNumber) {
	var list = [];
	var originalGameId = gameId;
	var gameId = "chop" + gameId;
	var flipper = 220;
	var counter = 0;
	for (let i = 0; i < flipper; ) {
		let newNumber = Math.floor(Math.random() * total) + 1;
		// Don't put the same number next to each other
		if (list[list.length - 1] != newNumber) {
			list.push(newNumber);
			i++;
		}
	}
	for (const [i, item] of list.entries()) {
		var algorithm = counter * (counter / 12 + 20);
		var numOutput = item;
		if (i < 200) {
			await sleep(10);
		} else if (i < 219) {
			await sleep(algorithm);
			counter++;
		} else {
			await sleep(2000);
			numOutput = winningNumber;
			clearItem(gameId);
			var element = document
				.getElementById(gameId)
				.getElementsByClassName("slot");

			element[numOutput - 1].classList.remove("locked");
			element[numOutput - 1].classList.add("winner");
		}

		// DEBUG Only:
		// This output allows you to visualize the
		// algorithm for slowing the spinner down.

		// console.log("i == " + i + " algorithm == " + algorithm)

		clearItem(gameId);
		var element = document.getElementById(gameId);
		if (element == null) {
			return;
		}
		var litClasses = element.getElementsByClassName("slot");
		if (litClasses == null) {
			return;
		}

		litClasses[numOutput - 1].classList.add("winnerAnim");
	}
}
