import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Outlet } from 'react-router-dom';
import logo from '../assets/ChopChop-MdResLogo.png';

export default function Home() {

    return (

        <div className="wrapper">
            <div className="home riseabove">
                <Outlet />
                {/* <Header></Header> */}
                <section>
                    <div className="jumbotron jumbotron-fluid py-5">
                        <div className="container text-center py-5">

                            <div className="logobox">
                                <img className="hero-logo" src={logo} />
                            </div>
                            <h1 className="display-4 logo">ChopChop</h1>
                            <p className="opener">ChopChop brings you a lightning-fast, high-odds, defi game.
                                Buy slots before others do.
                                After all slots in a game have been claimed,
                                a winning slot is chosen and the owner is paid.
                            </p>

                            <div className="mt-4">
                                <Link className="btn btn-primary px-5 mr-3" to="/game">PLAY NOW</Link>
                            </div>
                            <ul className="opener-list">
                                <li>Fast Games</li>
                                <li>100% provably fair</li>
                                <li>Instant Payouts</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
            <Footer></Footer>
        </div>
    )
}
