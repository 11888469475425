import React, { Component, useEffect, useRef } from "react";
import Header from "../components/header";
import { auth, db } from "../services/firebase";
import { ref, getDatabase } from "firebase/database";
import { Link, Outlet } from "react-router-dom";
import SidebarNav from "../components/sidebarNav";
import {
	RecoilRoot,
	atom,
	selector,
	useRecoilState,
	useRecoilValue,
} from "recoil";
import { useState } from "react";
import {
	currency,
	walletAddress,
	isWalletConnected,
	requestingConnection,
	balance,
	user,
	nameList,
} from "../atoms/atoms";
import { addressShortened } from "../atoms/selectors";
import useFetchNames from "../components/nameManager.js";
import moment from "moment";
import Avatar from "boring-avatars";

export default function Chat() {
	const [state, setState] = useState({
		user: auth().currentUser,
		content: "",
		readError: null,
		writeError: null,
		loadingChats: true,
	});
	const [chats, setChats] = useState();
	const [initialScroll, setInitialScroll] = useState(true);
	const [names, setNames] = useRecoilState(nameList);
	const [fetchNames] = useFetchNames();
	const userObject = useRecoilValue(user);
	const chatBox = useRef(null);
	const chatForm = useRef(null);
	const walletConnStatus = useRecoilValue(isWalletConnected);
	const addressShort = useRecoilValue(addressShortened);
	const address = useRecoilValue(walletAddress);
	const divRef = useRef(null);
	const sanitize = require("sanitize-html");

	useEffect(() => {
		if (userObject && userObject.uid) {
		}
	}, [userObject]);

	useEffect(() => {
		const ref = db.ref("chats");
		// declare the data fetching function
		function unique(array, propertyName) {
			return array.filter(
				(e, i) =>
					array.findIndex((a) => a[propertyName] === e[propertyName]) === i
			);
		}

		ref
			.endAt()
			.limitToLast(100)
			.on("value", (snapshot) => {
				let chatList = [];
				let ids = [];
				let id = "";

				snapshot.forEach((snap) => {
					if (!snap.val().sanitized) {
						return;
					}
					id = String(snap.val().uid);

					// USE this to find out if nameState has
					// specific id. If not, then push new id up to name state.
					// myObj.hasOwnProperty('key')

					ids.push(id);
					chatList.push(snap.val());
				});
				chatList.sort((a, b) => a.timestamp - b.timestamp);
				setChats(chatList);

				const uniqueIds = [...new Set(ids)];
				fetchNames(uniqueIds);
			});
		return () => ref.off();
	}, [ref]);

	useEffect(() => {
		// See if we should scroll the newest
		// chat into view. Don't do it
		// if the user has scrolled up
		// some significant distance
		var chatArea = document.querySelector(".chat-area");
		var chatHeightDiffPlus = chatArea.offsetHeight + chatArea.scrollTop + 400;
		var scrollHeight = chatArea.scrollHeight;
		// Sanity check, waiting on chats to load.
		if (scrollHeight < 100) {
			return;
		}
		if (chatHeightDiffPlus >= scrollHeight || initialScroll) {
			setInitialScroll(false);
			divRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [chats]);

	function handleChange(event) {
		var message = event.target.value;

		if (message.length > 500) {
			setState({ error: "Too many characters, reduce message length..." });
			return;
		}
		setState({
			content: message,
		});
	}

	async function handleSubmit(event) {
		event.preventDefault();
		var saniMessage = sanitize(state.content, {
			allowedTags: [],
			allowedAttributes: {},
		});
		if (!saniMessage) {
			setState({ content: "", error: "Error: Invalid entry..." });
			return;
		}
		const submitDateTime = Date.now();
		try {
			await db
				.ref("last_message/" + userObject.uid + "/timestamp")
				.set(submitDateTime);
		} catch (error) {
			console.log("Error: Forbidden");
		}

		try {
			await db.ref("chats").push({
				content: state.content,
				timestamp: submitDateTime,
				uid: userObject.uid,
				sanitized: false,
			});

			setState((state) => ({ ...state, content: "" }));
		} catch (error) {
			console.log("Error: Forbidden");
		}
	}
	function onEnterPress(e) {
		if (e.keyCode == 13 && e.shiftKey == false) {
			e.preventDefault();
			handleSubmit(e);
		}
	}

	return (
		<>
			<div className="chat-area">
				<ul className="chat-scroller">
					{chats &&
						chats.map((chat, i) => {
							return (
								<li key={i} className={"chat-bubble"}>
									<div className="chat-content">
										<div className="avatar">
											<Avatar
												size={30}
												name={chat.uid}
												variant="marble"
												colors={[
													"#704C09",
													"#D69111",
													"#B0770F",
													"#8E00FF",
													"#FF00F7",
												]}
											/>
										</div>

										<div className="cont-area">
											<div className="username">
												{names[chat.uid]
													? names[chat.uid]
													: `${chat.uid.slice(0, 6)}...${chat.uid.slice(
															chat.uid.length - 4,
															chat.uid.length
													  )}`}{" "}
											</div>
											<div className="text">{chat.content}</div>
										</div>
										<div className="chat-time float-right">
											{moment(chat.timestamp).fromNow()}
										</div>
										<br />
									</div>
								</li>
							);
						})}
					<div ref={divRef} />
				</ul>
			</div>

			<form onSubmit={handleSubmit} ref={chatForm} className="">
				{/* TODO: Set up local storage to keep track of 
            previous logins. If previous login, message
            should read: Attempting to re-connect wallet. Please give us a moment. */}

				{address && (
					<div className="fcontain">
						{userObject && (
							<div className="small-name">
								Username: <b>{userObject.name}</b>
							</div>
						)}
						{walletConnStatus}
						<textarea
							ref={chatBox}
							className="form-control"
							name="content"
							onKeyDown={onEnterPress}
							onChange={handleChange}
							value={state.content}
							placeholder="Type to chat..."
						></textarea>

						<button type="submit" className="btn btn-submit">
							Send
						</button>
						{state.error ? <p className="text-danger">{state.error}</p> : null}
					</div>
				)}
				{!address && (
					<div className="fcontain">
						<div className="err-wal">Connect wallet to chat.</div>
					</div>
				)}
			</form>
		</>
	);
}
