import { selector } from "recoil";
import { balance, walletAddress } from "./atoms";

// Seletors to change atom data used in Recoil
const balanceNormalized = selector({
    key: 'BalanceNormalized',
    get: ({ get }) => {
        const updatedBalance = get(balance) / 1000000
        return updatedBalance;
    }
});
const addressShortened = selector({
    key: 'AddressShort',
    get: ({ get }) => {
        const currAddress = get(walletAddress)
        var slicedUp = currAddress.slice(0, 6) + '...' + currAddress.slice(
            currAddress.length - 4,
            currAddress.length
        );
        return slicedUp;
    }
});

export { balanceNormalized, addressShortened }