import { ToastContainer, toast } from "react-toastify";
import metamaskIcon from "../assets/metamask.png";
import chopIcon from "../assets/ChopChop-Icon.png";
import avalancheIcon from "../assets/avalanche-avax-logo.jpg";

const success = (message) => {
	toast.success(message, {
		position: "top_center",
	});
};
// Game Won notification
// New Game Starting notification

const winnerNotify = (message) => {
	toast.success(message, {
		position: "top-center",
		// autoClose: false,
		icon: ({ theme, type }) => <img src={chopIcon} />,
		progressClassName: "bid-error-progress",
	});
};
const publicNotify = (message) => {
	toast.info(message, {
		position: "bottom-right",
		icon: ({ theme, type }) => <img src={chopIcon} />,
		progressClassName: "bid-error-progress",
		hideProgressBar: true,
		pauseOnFocusLoss: false,
		autoClose: 5000,
	});
};
const bidConfirmed = (message) => {
	toast.success(message, {
		position: "bottom-right",
		icon: ({ theme, type }) => <img src={chopIcon} />,
		progressClassName: "bid-error-progress",
		hideProgressBar: true,
		pauseOnFocusLoss: false,
		autoClose: 5000,
	});
};
const error = (message) => {
	toast.warning(message, {
		position: "bottom-right",
		toastId: "bid-error",
		progressClassName: "bid-error-progress",
		autoClose: 5000,
	});
};

// CURRENTLY UNUSED
const walletMessage = ({ closeToast, toastProps }) => (
	<div className="no-wallet">
		<h3>No wallet found.</h3>
		<p>Please, install a wallet to play!</p>
		<ul className="metamask-install">
			<li>
				<a href="https://metamask.io" target="_blank">
					<img src={metamaskIcon} />
					<span>Install Metamask</span>
				</a>
			</li>
		</ul>
	</div>
);

const wrongChain = ({ closeToast, toastProps }) => (
	<div className="no-wallet">
		<h3>
			<img src={avalancheIcon} /> Wrong Network
		</h3>
		<p>Please, connect to the Avalanche network to play. </p>
	</div>
);
const chain = () => {
	toast(wrongChain, {
		position: "top-center",
		autoClose: false,
		toastId: "wrong-network",
		hideProgressBar: true,
		icon: "",
	});
};

export default {
	success,
	error,
	walletMessage,
	chain,
	winnerNotify,
	publicNotify,
	bidConfirmed,
};
