import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAnalytics, logEvent } from "firebase/analytics";

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
firebase.initializeApp(config);

const analytics = getAnalytics();
const auth = firebase.auth;
const db = firebase.database();

const rtdb = getDatabase();
const localAuth = getAuth();
const functions = getFunctions();
if (
	window.location.hostname === "localhost" ||
	window.location.hostname === "local.chopchop.gg"
) {
	console.log("Are we local? YES!");
	// Point to the RTDB emulator running on localhost.
	connectDatabaseEmulator(rtdb, "localhost", 5002);
	connectAuthEmulator(localAuth, "http://localhost:9099");
	connectFunctionsEmulator(functions, "localhost", 5001);
}
export { auth, db, localAuth, analytics, logEvent };
