import { auth, db } from "../services/firebase";

export default function CreateGame(event) {
	async function createNewGame() {
		const submitDateTime = Date.now();
		let totTickets = 8;
		let payout = 7;
		let costPerTicket = 1;
		let tickets = {};
		for (let i = 1; i <= totTickets; i++) {
			tickets[i] = { buyer: null, status: "open" };
		}
		try {
			await db.ref("games/pending").push({
				totTickets: totTickets,
				payout: payout,
				costPerTicket: costPerTicket,
				timestamp: submitDateTime,
				tickets: tickets,
				winningTicket: 0,
				seed: "QSE3yPkQTUXMwH0hQb",
				seedHash:
					"b1e919b83e5ecb823f2470b93b8fb45e6d96774acd83ee138fe90324fe2a04f5",
			});
			console.log("Game Starting");
		} catch (error) {
			// setState({ writeError: error.message });
			console.log("Firebase Error:", error.message);
		}
	}
	return (
		<button className="btn btn-primary" onClick={createNewGame}>
			Create New Game
		</button>
	);
}
