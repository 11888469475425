import { getAuth, signInWithCustomToken } from "firebase/auth";
import { auth, db, localAuth } from "../services/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";


// Set up callable functions for JWT creation
const functions = getFunctions();
const createToken = httpsCallable(functions, 'createToken-createToken');


export async function HandleLogIn(account) {
    try {

        let token = await createToken({ address: account });
        token = token.data;
        let signedIn = {};
        signedIn = await signInWithCustomToken(localAuth, token);

        if (signedIn) {
            document.cookie = "token=" + token;
            return signedIn.user.uid;
        }
        return false;
    } catch (error) {
        console.log(error);
    }

}

export default HandleLogIn;