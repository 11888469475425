import { React, useState } from "react";
import Chat from "../pages/chat";
import {
	RecoilRoot,
	atom,
	selector,
	useRecoilState,
	useRecoilValue,
} from "recoil";
import PastGames from "../pages/pastGames";
import SidebarNav from "./sidebarNav";
import {
	currency,
	walletAddress,
	isWalletConnected,
	requestingConnection,
	balance,
	user,
	sidebarView,
	sidebarToggle,
} from "../atoms/atoms";
import Profile from "./profile";
import chatIcon from "../assets/chat.svg";

export default function Sidebar() {
	const [sideBarView, setSideBarView] = useRecoilState(sidebarView);
	const [sidebarToggleState, setSidebarToggleState] =
		useRecoilState(sidebarToggle);

	function toggleModal() {
		setSidebarToggleState(true);
		console.log("Toggle the chat");
	}

	return (
		<>
			<div className="sidebar-toggle">
				<button className="chat" onClick={toggleModal}>
					Chat ...
				</button>
				<Profile />
			</div>

			<div className={`sidebar ${sidebarToggleState ? "show" : ""}`}>
				<div className="sidebar-container">
					<SidebarNav />
					{sideBarView === "chat" && <Chat />}
					{sideBarView === "past-games" && <PastGames />}
				</div>
			</div>
		</>
	);
}
