import React from "react";
import {
	RecoilRoot,
	atom,
	selector,
	useRecoilState,
	useRecoilValue,
} from "recoil";
import { useEffect, useState } from "react";
import { auth, db, analytics, logEvent } from "../services/firebase";
import {
	currency,
	walletAddress,
	isWalletConnected,
	requestingConnection,
	balance,
	user,
	sidebarView,
	profileModal,
	nameList,
} from "../atoms/atoms";
import Avatar from "boring-avatars";
import { addressShortened } from "../atoms/selectors";
import checkmarkIcon from "../assets/checkmark-icon.svg";
import editIcon from "../assets/edit-icon.svg";
import Button from "react-bootstrap/Button";
import { getFunctions, httpsCallable } from "firebase/functions";

process.env.TZ = "America/Chicago";

export default function ProfileModal() {
	const [profileVisible, setProfileVisible] = useRecoilState(profileModal);
	const [isEditable, setIsEditable] = useState(false);
	const address = useRecoilValue(walletAddress);
	const [userObject, setUserObject] = useRecoilState(user);
	const [names, setNames] = useRecoilState(nameList);
	const [newName, setNewName] = useState("");
	const [nameErr, setNameErr] = useState("");
	const [gamesWon, setGamesWon] = useState(0);
	const [gamesPlayed, setGamesPlayed] = useState(0);
	const functions = getFunctions();

	useEffect(() => {
		if (!address) {
			return;
		}
		const ref = db.ref("users/ids/" + address + "/games");
		ref.on("value", (snapshot) => {
			if (!snapshot.exists()) {
				return;
			}
			if (snapshot.val().won) {
				var won = Object.keys(snapshot.val().won).length;
				setGamesWon(won);
			}
			if (snapshot.val().played) {
				var played = Object.keys(snapshot.val().played).length;
				setGamesPlayed(played);
			}
		});
	}, [address]);

	useEffect(() => {
		setNewName(userObject.name);
	}, [userObject]);
	const handleNameChange = (event) => {
		event.persist();
		setNewName(event.target.value);
	};
	function openProfile() {}
	function closeProfile() {
		setProfileVisible(false);
		setNameErr("");
	}
	function makeEditable() {
		setIsEditable(true);
	}
	const saveUsername = async () => {
		if (userObject.name === newName) {
			setIsEditable(false);
			setNameErr("");
			return;
		}
		const changeName = httpsCallable(functions, "nameControls-nameChange");

		const result = changeName({ user: userObject, newName: newName })
			.then((result) => {
				const name = result.data.name;
				let newUserObject = {
					...userObject,
					name: name,
				};
				setUserObject(newUserObject);
				setNames((names) => ({
					...names,
					[newUserObject.uid]: name,
				}));
				setIsEditable(false);
				setNameErr("");
			})
			.catch((error) => {
				const code = error.code;
				const message = error.message;
				const details = error.details;
				setNameErr(message);
				setNewName(userObject.name);
				setIsEditable(false);
			});
	};
	return (
		<>
			{profileVisible && (
				<div className="profile-modal-shroud">
					<div className="profile-modal">
						<Button className="btn-primary close-modal" onClick={closeProfile}>
							✖️
						</Button>
						<div className="profile-header">
							<div className="header">
								<h2>ChopChop - Profile</h2>
							</div>
						</div>
						<div className="profile-body">
							<div className="profile-pic">
								<Avatar
									size={150}
									name={address}
									variant="marble"
									colors={[
										"#704C09",
										"#D69111",
										"#B0770F",
										"#8E00FF",
										"#FF00F7",
									]}
								/>
							</div>
							<div className="profile-data">
								<div className="stats">
									<div className="games-played statbox">
										<span>Games Played:</span>
										<span className="stat">{gamesPlayed}</span>
									</div>
									<div className="games-won statbox">
										<span>Games Won:</span>
										<span className="stat">{gamesWon}</span>
									</div>
								</div>
								<div className="name data-box">
									<label for="username">Name:</label>
									<input
										id="username"
										className={isEditable ? `editable` : ``}
										type="text"
										name="username"
										readOnly={!isEditable}
										value={newName ? newName : ""}
										// value="Test"
										onChange={handleNameChange}
									/>

									{!isEditable && (
										<Button className="edit" onClick={makeEditable}>
											<img src={editIcon} />{" "}
										</Button>
									)}
									{isEditable && (
										<Button className="edit" onClick={saveUsername}>
											<img src={checkmarkIcon} />
										</Button>
									)}
								</div>
								<div className="err">{nameErr}</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
