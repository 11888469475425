import React from "react";
import {
	RecoilRoot,
	atom,
	selector,
	useRecoilState,
	useRecoilValue,
} from "recoil";
import {
	currency,
	walletAddress,
	isWalletConnected,
	requestingConnection,
	balance,
	user,
	sidebarView,
	profileModal,
} from "../atoms/atoms";
import settingsIcon from "../assets/settings-icon.svg";
import Button from "react-bootstrap/Button";

export default function Profile() {
	const [profileVisible, setProfileVisible] = useRecoilState(profileModal);
	const address = useRecoilValue(walletAddress);

	function openProfile() {
		setProfileVisible(true);
	}
	return (
		<>
			{address && (
				<Button
					variant="secondary"
					className="settings-icon"
					onClick={() => openProfile()}
				>
					<img src={settingsIcon} />
				</Button>
			)}
		</>
	);
}
