import React, { useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../services/firebase";
import WalletConnect from "./walletConnect";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import SmLogo from "../assets/ChopChop-SmResLogo.png";

function Header() {
	return (
		<header className="riseabove">
			<nav>
				<Navbar expand={false}>
					<Container>
						<div className="logohome">
							<Link className="navbar-brand" to="/">
								<img src={SmLogo} />
								ChopChop
							</Link>
						</div>
						<div className="wallet">
							<WalletConnect />
						</div>
						<div className="navtoggle">
							<Navbar.Toggle aria-controls="offcanvasNavbar" />
						</div>
						<Navbar.Offcanvas
							id="offcanvasNavbar"
							aria-labelledby="offcanvasNavbarLabel"
							placement="end"
						>
							<Offcanvas.Header closeButton>
								<Offcanvas.Title id="offcanvasNavbarLabel">
									<img src={SmLogo} /> ChopChop
								</Offcanvas.Title>
							</Offcanvas.Header>
							<Offcanvas.Body>
								<Nav className="justify-content-end flex-grow-1 pe-3">
									<Nav.Link href="/">Home</Nav.Link>
									<Nav.Link href="/game">Play Game</Nav.Link>
									<Nav.Link href="https://docs.chopchop.gg" target="_blank">
										Docs
									</Nav.Link>
									<Nav.Link disabled href="#">
										Player Stats <small>...coming soon...</small>
									</Nav.Link>

									{/* <NavDropdown title="Dropdown" id="offcanvasNavbarDropdown">
                                        <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                                        <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action5">
                                            Something else here
                                        </NavDropdown.Item>
                                    </NavDropdown> */}
								</Nav>
							</Offcanvas.Body>
						</Navbar.Offcanvas>
					</Container>
				</Navbar>
				{/* <nav className="navbar navbar-expand-sm fixed-top navbar-light bg-light">
                <Link className="navbar-brand" to="/">ChopChop</Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <GetTronWeb />
                <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                    {auth().currentUser
                        ? <div className="navbar-nav">
                            <Link className="nav-item nav-link mr-3" to="/chat">Profile</Link>
                            <button className="btn btn-primary mr-3" onClick={() => auth().signOut()}>Logout</button>
                        </div>
                        : <div className="navbar-nav">
                            <Link className="nav-item nav-link mr-3" to="/login">Sign In</Link>
                            <Link className="nav-item nav-link mr-3" to="/signup">Sign Up</Link>
                        </div>}
                </div>
            </nav> */}
			</nav>
		</header>
	);
}

export default Header;
