import React, { Component, useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Link, Outlet } from "react-router-dom";
import CreateGame from "../components/createGame";
import Settings from "../components/admin-settings";
import axios from "axios";
import {
	GoogleOAuthProvider,
	GoogleLogin,
	googleLogout,
} from "@react-oauth/google";

import {
	RecoilRoot,
	atom,
	selector,
	useRecoilState,
	useRecoilValue,
} from "recoil";

export default function Admin() {
	// Google Login Settings
	const [isAdmin, setIsAdmin] = useState(false);
	const google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
	const [errorState, setErrorState] = useState("");
	const [gameVals, setGameVals] = useState({
		payout: "",
		totTickets: "",
		restart: true,
	});

	const handleLogin = async (response) => {
		var userToken = response.credential;
		// if (
		// 	window.location.hostname === "localhost" ||
		// 	window.location.hostname === "local.chopchop.gg"
		// ) {
		// 	localStorage.setItem("jwt-login", userToken);
		// 	setIsAdmin(true);
		// 	return;
		// }
		try {
			const url = process.env.REACT_APP_BACKEND_URL;
			const endpoint = "/user-validation";
			const validateToken = await axios
				.post(url + endpoint, {
					authorization: userToken,
				})
				.then((res) => {
					if (res.data.isValid) {
						localStorage.setItem("jwt-login", userToken);
						setErrorState("");
						setIsAdmin(true);
					}
				})
				.catch((error) => {
					setErrorState("Not Authorized");
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};
	const handleLogout = () => {
		googleLogout();
		setIsAdmin(false);
		localStorage.removeItem("jwt-login");
	};
	const loginFailure = (error) => {
		console.log("Login Error: " + error);
	};
	const handlePayoutInputChange = (event) => {
		event.persist();
		setGameVals((gameVals) => ({
			...gameVals,
			payout: event.target.value,
		}));
	};

	const handleTotTicketsInputChange = (event) => {
		event.persist();
		setGameVals((gameVals) => ({
			...gameVals,
			totTickets: event.target.value,
		}));
	};
	const handleRestartInputChange = (event) => {
		event.persist();
		setGameVals((gameVals) => ({
			...gameVals,
			restart: event.target.value,
		}));
	};

	const startGame = async (e) => {
		e.preventDefault();
		console.log(gameVals);
		// setGameVals({
		// 	payout: "",
		// 	totTickets: "",
		// 	restart: true,
		// });

		try {
			console.log("AXIOS: ", gameVals);
			const url = process.env.REACT_APP_BACKEND_URL;
			const endpoint = "/start-game";
			const response = await axios
				.post(url + endpoint, {
					game: gameVals,
					authorization: localStorage.getItem("jwt-login"),
				})
				.then((res) => {
					console.log(res);
				})
				.catch((error) => {
					console.log("AXIOS ERROR: ", error);
				});
		} catch (error) {
			console.log(error.response.body);
		}
	};

	useEffect(() => {
		// Get JWT if exists
		var authorization = localStorage.getItem("jwt-login");
		if (authorization) {
			handleLogin({ credential: authorization });
		}
	}, []);

	return (
		<GoogleOAuthProvider clientId={google_client_id}>
			<div className="wrapper adminwrap">
				<Header></Header>

				<div className="admin riseabove">
					{!isAdmin && (
						<div className="login">
							<h1>ChopChop Admin</h1>
							<GoogleLogin
								onSuccess={(credentialResponse) => {
									handleLogin(credentialResponse);
								}}
								onError={() => {
									loginFailure("Login Failed");
								}}
							/>
							{errorState && <div className="error-state">{errorState}</div>}
						</div>
					)}
					{isAdmin && (
						<div className="sidebar">
							<Settings />
						</div>
					)}
					<div className="admin-container">
						{isAdmin && (
							<div className="admin-column">
								ADMIN PAGE
								<CreateGame />
								<form action="">
									<h2>Start New Game</h2>
									<label>
										Payout:
										<input
											id="payout"
											className="form-field"
											type="number"
											placeholder="Payout in $"
											name="payout"
											value={gameVals.payout}
											onChange={handlePayoutInputChange}
										/>
									</label>
									<label>
										Total Tickets:
										<input
											id="total-tickets"
											className="form-field"
											type="number"
											placeholder="Total # of Tickets"
											name="total-tickets"
											value={gameVals.totTickets}
											onChange={handleTotTicketsInputChange}
										/>
									</label>
									<label>
										Restart Game Upon Completion:
										<input
											id="restart"
											className="form-field"
											type="text"
											placeholder="true/false"
											name="restart"
											value={gameVals.restart}
											onChange={handleRestartInputChange}
										/>
									</label>

									<button className="btn btn-primary" onClick={startGame}>
										Start Game
									</button>
								</form>
								<button className="logout" onClick={handleLogout}>
									Logout
								</button>
							</div>
						)}
					</div>
					<Footer></Footer>
				</div>
			</div>
		</GoogleOAuthProvider>
	);
}
