export const avaxLocal = {
	id: 1337,
	name: "Avalanche - Local",
	nativeCurrency: { name: "Avalanche - Local", symbol: "AVAX", decimals: 18 },
	rpcUrls: {
		default: {
			http: ["http://localhost:8545"],
		},
	},

	testnet: true,
};

export const avalancheFuji = {
	id: 43113,
	name: "Avalanche Fuji",
	nativeCurrency: {
		decimals: 18,
		name: "Avalanche Fuji",
		symbol: "AVAX",
	},
	rpcUrls: {
		default: {
			http: ["https://api.avax-test.network/ext/bc/C/rpc"],
		},
	},
	blockExplorers: {
		default: {
			name: "SnowTrace",
			url: "https://testnet.snowtrace.io",
			apiUrl: "https://api-testnet.snowtrace.io/api",
		},
	},
	contracts: {
		multicall3: {
			address: "0xca11bde05977b3631167028862be2a173976ca11",
			blockCreated: 7096959,
		},
	},
	testnet: true,
};
