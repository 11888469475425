import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link, Outlet } from 'react-router-dom'
import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
} from 'recoil';
import { currency, walletAddress, isWalletConnected, requestingConnection, balance, user, sidebarView, profileModal } from '../atoms/atoms'

const PrivateRoute = () => {
    const [userObject, setUserObject] = useRecoilState(user);
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    if (userObject != null) {
        return <Outlet />
    }
    return <Navigate to="/" />
}

export { PrivateRoute }